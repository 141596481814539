import moment from "moment"

export const useDateTime = () => {
  const parseFullDate = (dateTime) => {
    if (!dateTime) return ''
    return moment(dateTime).format('DD MMMM YYYY hh:mm:ss')
  }

  const parseFullDate24 = (dateTime) => {
    if (!dateTime) return ''
    return moment(dateTime).format('DD MMMM YYYY HH:mm:ss')
  }

  const parseDateTime = (dateTime) => {
    if (!dateTime) return ''
    const split = dateTime.split(/\D+/)
    const newDateTime = new Date(
      split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
    )
    
    return moment(newDateTime).format('DD MMM YYYY hh:mm:ss')
  }

  const parseDate = (dateTime) => {
    if (!dateTime) return ''
    const split = dateTime.split(/\D+/)
    const newDate = new Date(
      split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
    )
  
    return moment(newDate).format('Do MMMM YYYY')
  }

  const parseISODate = (dateTime) => {
    if (!dateTime) return ''
    const split = dateTime.split(/\D+/)
    const newDateTime = new Date(
      split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
    )
    
    return moment(newDateTime).format('MM-DD-YYYY hh:mm:ss')
  }

  return { parseFullDate, parseFullDate24, parseDateTime, parseDate, parseISODate }
}
